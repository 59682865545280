import {CSSProperties, PropsWithChildren} from 'react'

type Props = {
    style? :CSSProperties
    onClick? :() => void
}

const ComponentDivCenter = ({ style = {}, onClick, children } :PropsWithChildren<Props>) => {
    const innerStyle :CSSProperties = {...style, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}
    return <div style={innerStyle} onClick={onClick}>
        { children }
    </div>
}

export default ComponentDivCenter