import {Background, BackgroundVariant, Controls, MiniMap, ReactFlow} from '@xyflow/react'
import {
    TypeReactFlowEdge,
    TypeReactFlowEdgeOptionsDefault,
    TypeReactFlowNode,
    TypeReactFlowNodeTypes
} from 'Type'

import '@xyflow/react/dist/style.css'

type Props = {
    nodes? :TypeReactFlowNode[]
    edges? :TypeReactFlowEdge[]
    defaultNodes? :TypeReactFlowNode[]
    defaultEdges? :TypeReactFlowEdge[]
    defaultEdgeOptions? :TypeReactFlowEdgeOptionsDefault<TypeReactFlowEdge>
    fitView? :boolean
    nodeTypes? :TypeReactFlowNodeTypes

}

const ComponentReactFlow = ({ nodes, edges, defaultNodes, defaultEdges, defaultEdgeOptions, fitView = true, nodeTypes } :Props) => {
    return <div style={{ width: '100%', height: '100%' }}>
        <ReactFlow nodes={nodes} edges={edges} defaultNodes={defaultNodes} fitView={fitView}
                   defaultEdges={defaultEdges} defaultEdgeOptions={defaultEdgeOptions} nodeTypes={nodeTypes}>
            <Controls />
            <MiniMap />
            <Background variant={BackgroundVariant.Dots} gap={24} size={1} />
        </ReactFlow>
    </div>
}

    export default ComponentReactFlow