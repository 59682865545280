import { EuiText } from '@elastic/eui'
import {CSSProperties, ReactNode} from 'react'


type Props = {
    textAlign? :'left' | 'right' | 'center'
    size? :'xs' | 's' | 'm' | 'relative'
    color? :string | 'default' | 'accent' | 'success' | 'warning' | 'danger' | 'ghost' | 'subdued'
    grow? :boolean
    whiteSpace? : 'nowrap' | 'normal' | 'pre'
    children? :ReactNode
    onClick? :() => void
    className? :string
    style? :CSSProperties
}

const ComponentEuiText = ({textAlign, size, color, grow, whiteSpace = 'normal', onClick, className, style, children} :Props) => {
    const innerStyle :CSSProperties = { ...style, whiteSpace: whiteSpace }
    return <EuiText textAlign={textAlign} size={size} color={color} grow={grow} style={innerStyle} onClick={onClick} className={className}>{ children }</EuiText>
}


export default ComponentEuiText