import { useEffect, useState } from 'react'
import {TypeHttpControlUrl, TypeWebSocketControl, TypeWebSocketPayloadAcc} from 'Type'
import {HookBrowserTabActive, HookWebSocketUrl} from 'Hook'
import { ClassClientSocket } from 'Class'

type Props = {
    path? :string
}

const HookWebSocket = <M extends TypeWebSocketPayloadAcc>({ path } :Props) :TypeWebSocketControl<M> => {

    const { url } :TypeHttpControlUrl = HookWebSocketUrl(path)

    const [client, setClient] = useState<ClassClientSocket<M> | undefined>()
    const [data, setData] = useState<TypeWebSocketControl<M>>({ messages: [], latestMessage :undefined, isConnected :false})

    const tabReactivated :boolean = HookBrowserTabActive()

    console.log(client, tabReactivated, data)

    const connect = () => {
        if (url) {
            console.log(`connect ${url}`)
            if (client) client.permanentlyClose()
            setClient(new ClassClientSocket(url, setData))
        }
    }

    const disconnect = () => {
        if (client) {
            console.log(`disconnect ${url}`)
            client.permanentlyClose()
        }
    }

    const reconnect = () => {
        console.log(`reconnect`, client, url)
        if (client && url) {
            client.permanentlyClose()
            setClient(new ClassClientSocket(url, setData, client.state))
        } else {
            connect()
        }
    }

    useEffect(() => {
        console.log(`useEffect connect`, client, url)
        connect()
        return () => { disconnect() }
    }, [url])

    useEffect(() => {
        console.log(`useEffect tebReactivated`, tabReactivated)
        if (tabReactivated) reconnect()
    }, [tabReactivated])

    return data
}

export default HookWebSocket