import {
    ComponentEuiCallout,
    ComponentEuiModalBody,
    ComponentEuiModalFooter,
    ComponentEuiModalHeader,
    ComponentEuiModalHeaderTitle,
    ComponentNavForm,
    ComponentNavFormButtonSubmit
} from 'Component'
import {HookNavAction, HookNavFormPayloadBuild} from 'Hook'
import {useEffect} from 'react'
import {RecordNavAction} from 'Record'
import {TypeHttpPayload, TypeNav, TypeNavControlActionType, TypeNavHttpControlAction} from 'Type'
import {UtilString} from 'Util'

type Props<T> = {
    title: string
    isTitleVerb?: boolean
    nav: TypeNav<T>
    actionType: TypeNavControlActionType
    onSuccess: (payload :TypeHttpPayload, res? :T) => Promise<void> | any
}

const ComponentNavAction = <T, >({title, isTitleVerb, nav, actionType, onSuccess}: Props<T>) => {

    const control: TypeNavHttpControlAction<T> = HookNavAction({actionType, nav});

    const respNav: TypeNav<T> | undefined = control.res?.data

    const isRequestSuccess: boolean = Boolean(control.res && !control.res.error)

    const isActionSuccess: boolean = Boolean(respNav && !respNav.error)

    const payload: TypeHttpPayload = HookNavFormPayloadBuild({nav, formPayload: control.payload})

    useEffect(() => {
        if (isRequestSuccess && isActionSuccess) onSuccess(control.payload, respNav?.data.entity)
    }, [isRequestSuccess, isActionSuccess, respNav?.data.entity])

    return <>
        <ComponentEuiModalHeader>
            <ComponentEuiModalHeaderTitle>{UtilString.capitalize(title)}</ComponentEuiModalHeaderTitle>
        </ComponentEuiModalHeader>
        <ComponentEuiModalBody>
            <ComponentNavForm nav={nav} actionType={actionType} onChange={control.addToPayload} payload={payload}
                              error={respNav?.error}/>
        </ComponentEuiModalBody>
        <ComponentEuiModalFooter>
            <ComponentNavFormButtonSubmit nav={nav} actionType={actionType}
                                          entityName={RecordNavAction[actionType].buttonText(title, isTitleVerb)}
                                          onClick={control.submit} isLoading={control.isLoading}
                                          isDisabled={!control.isActionable}/>
        </ComponentEuiModalFooter>
        {respNav?.error && <ComponentEuiCallout iconType={'alert'} color={'danger'} title={respNav.error.message}/>}
    </>
}

export default ComponentNavAction