import { EuiFilePicker, useGeneratedHtmlId } from '@elastic/eui'
import React from 'react'
import { TypeEuiFormFieldProps } from 'Type'


type Props = TypeEuiFormFieldProps<File> & {
    display? :'default' | 'large'
    accept? :string
}

const ComponentEuiFieldFile = ({value, onChange, display, accept} :Props) => {

    const id :string = useGeneratedHtmlId();

    const evtFn = (files :FileList | null) => { if (files && files[0]) onChange(files[0]) }

    return <EuiFilePicker
        id={id}
        display={display}
        initialPromptText="Select file"
        onChange={evtFn}
        accept={accept}
    />
}

export default ComponentEuiFieldFile