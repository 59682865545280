import { useEffect } from 'react'
import { ComponentEuiModalBody, ComponentEuiModalFooter, ComponentEuiModalHeader,
    ComponentEuiModalHeaderTitle, ComponentNavForm, ComponentNavFormButtonCancel, ComponentNavFormButtonSubmit } from 'Component'
import {HookNavAction, HookNavFormPayloadBuild } from 'Hook'
import { RecordNavAction } from 'Record'
import {TypeHttpPayload, TypeNav, TypeNavControlActionType, TypeNavHttpControlAction, TypeUrlParam} from 'Type'
import { UtilString } from 'Util'

type Props<T> = {
    entityName :string
    nav :TypeNav<T>
    onSuccess :(res :T) => Promise<void>
    onClose? :() => void
}

const actionType :TypeNavControlActionType = 'patch'

const ComponentNavActionPopoverPatch = <T,>({entityName, nav, onSuccess, onClose} :Props<T>) => {

    const control :TypeNavHttpControlAction<T> = HookNavAction({actionType, nav});

    const respNav :TypeNav<T> | undefined = control.res?.data

    const isRequestSuccess :boolean = Boolean(control.res && !control.res.error)

    const isActionSuccess :boolean = Boolean(respNav && !respNav.error)

    const isEmpty :boolean = Object.keys(control.payload).length === 0

    const payload :TypeHttpPayload = HookNavFormPayloadBuild({nav, formPayload: control.payload})

    useEffect(() => {
        if (isRequestSuccess && isActionSuccess && respNav?.data.entity) onSuccess(respNav?.data.entity)
    }, [isRequestSuccess, isActionSuccess, respNav?.data.entity])

    return <>
        <ComponentEuiModalHeader>
            <ComponentEuiModalHeaderTitle>{UtilString.capitalize(entityName)}</ComponentEuiModalHeaderTitle>
        </ComponentEuiModalHeader>
        <ComponentEuiModalBody>
            <ComponentNavForm nav={nav} actionType={actionType} onChange={control.addToPayload} payload={payload} error={respNav?.error}/>
        </ComponentEuiModalBody>
        <ComponentEuiModalFooter>
            { onClose && <ComponentNavFormButtonCancel onClick={onClose}/> }
            <ComponentNavFormButtonSubmit nav={nav} actionType={actionType} entityName={RecordNavAction[actionType].buttonText(entityName)} onClick={control.submit} isLoading={control.isLoading} isDisabled={isEmpty}/>
        </ComponentEuiModalFooter>
    </>
}

export default ComponentNavActionPopoverPatch