import { GlobalSystemColumn } from 'Global'
import { HookModuleTableColumnMove, HookModuleTableColumnSort } from 'Hook'
import { ReactNode, useEffect, useState } from 'react'
import { TypeControlSwitch, TypeEntityRow, TypeEntityTableVersionColumn, TypeEuiPropsDataGridCellValueElement,
    TypeEuiPropsDataGridColumn, TypeEuiPropsDataGridColumnVisibility, TypeEuiPropsDataGridControlColumn, TypeEuiPropsListGroupItem,
    TypeModuleTableColumnControl, TypeModuleTableColumnControlMove, TypeModuleTableColumnControlSort, TypeNav,
    TypeNavDataPageRequestSortDirection, TypeNavHttpControl } from 'Type'
import { FORMAT_TABLE_DATA_SYSTEM_COLUMNS } from 'Format'
import { ModuleTableColumnControlLink } from 'Module'

const rowId :string = FORMAT_TABLE_DATA_SYSTEM_COLUMNS.rowId

const columnAction = (label :string, icon :string, onClick :() => void) :TypeEuiPropsListGroupItem => {
    return {
        label: label,
        iconType: icon,
        onClick: onClick,
        size: 'xs'
    }
}

const columnMapper = (col :TypeEntityTableVersionColumn,
                      setToFilterColumn :(col :TypeEntityTableVersionColumn) => void,
                      move :(nameSlug :string, move :number) => void,
                      sort :(nameSlug :string, dir :TypeNavDataPageRequestSortDirection) => void) :TypeEuiPropsDataGridColumn => {
    return {
        id: col.nameCamel,
        displayAsText: col.name,
        initialWidth: (col.nameCamel === 'rowId') ? 80 : undefined,
        isSortable: true,
        actions: {
            showMoveLeft: columnAction('Move Left', 'sortLeft', () => {move(col.nameSlug, -1)}),
            showMoveRight: columnAction('Move Right', 'sortRight', () => {move(col.nameSlug, 1)}),
            showSortAsc: columnAction('Sort Ascending', 'sortAscending', () => {sort(col.nameCamel, 'asc')}),
            showSortDesc: columnAction('Sort Descending', 'sortDescending', () => {sort(col.nameCamel, 'desc')}),
            additional: [
                columnAction('Filter', 'filter', () => setToFilterColumn(col))
            ]
        }
    }
}

const columnSort = (a :TypeEntityTableVersionColumn, b :TypeEntityTableVersionColumn) :number => a.orderIndex - b.orderIndex

const columnFilterFn = (showSystemColumns :boolean) => (col :TypeEntityTableVersionColumn) :boolean => (!col.isSystem || col.nameCamel === rowId || showSystemColumns)

const HookModuleTableColumn = (pageNav :TypeNav<any>, columnControl :TypeNavHttpControl<TypeEntityTableVersionColumn>, data :TypeEntityRow[] | undefined) :TypeModuleTableColumnControl => {

    const moveControl :TypeModuleTableColumnControlMove = HookModuleTableColumnMove(columnControl)
    const { sort, sorting } :TypeModuleTableColumnControlSort = HookModuleTableColumnSort(pageNav, columnControl)
    const showSystemColumns :TypeControlSwitch = GlobalSystemColumn()
    const [toFilterColumn, setToFilterColumn] = useState<TypeEntityTableVersionColumn | undefined>()
    const clearToFilterColumn :() => void = () => setToFilterColumn(undefined)

    const columns :TypeEntityTableVersionColumn[] = (columnControl.res?.data?.data.page?.content ?? []).filter(columnFilterFn(showSystemColumns.value)).sort(columnSort)

    const refreshKey :string = columns.map((c :TypeEntityTableVersionColumn) => c.nameSlug).join('_')

    const gridColumns :TypeEuiPropsDataGridColumn[] = columns.map(c => {return columnMapper(c, setToFilterColumn, moveControl.moveColumn, sort)}) ?? []

    const [visibleColumns, setVisibleColumns] = useState<string[]>(gridColumns.map(c => c.id))

    useEffect(() => { setVisibleColumns(gridColumns.map(c => c.id)) }, [columnControl.url, refreshKey])

    const columnVisibility :TypeEuiPropsDataGridColumnVisibility = {
        visibleColumns: visibleColumns,
        setVisibleColumns: (visibleColumns :string[]) => {setVisibleColumns([...visibleColumns])}
    }

    const leadingControlColumns :TypeEuiPropsDataGridControlColumn[] = [
        {
            id: 'link',
            width: 30,
            headerCellRender: () => <></>,
            rowCellRender :(props :TypeEuiPropsDataGridCellValueElement) :ReactNode => {
                return data && <div style={{marginRight: '3px', float: 'right'}}>
                    <ModuleTableColumnControlLink row={data[props.rowIndex]}/>
                </div>
            }
        }
    ]

    const trailingControlColumns :TypeEuiPropsDataGridControlColumn[] = []

    return { gridColumns, columnVisibility, leadingControlColumns, trailingControlColumns, toFilterColumn, clearToFilterColumn, sorting }
}

export default HookModuleTableColumn