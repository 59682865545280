import { ComponentEuiButton } from 'Component'
import { HookNavAction } from 'Hook'
import {TypeEntityRequester, TypeNav, TypeNavHttpControl, TypeNavHttpControlAction } from 'Type'

type Props = {
    reqControl :TypeNavHttpControl<TypeEntityRequester>
}

const ModuleVersionRequesterButton = ({ reqControl } :Props) => {

    const reqNav :TypeNav<TypeEntityRequester> | undefined = reqControl.res?.data

    const actionControl :TypeNavHttpControlAction<TypeEntityRequester> = HookNavAction<TypeEntityRequester>({actionType: 'post', nav: reqNav})

    const onClick = async () => {
        await actionControl.submit()
        reqControl.submit()
    }

    return (actionControl.isActionable) ? <ComponentEuiButton color={'success'} type={'button'} iconType={'bolt'} size={'s'} onClick={onClick}>Make Connectable</ComponentEuiButton> : null
}

export default ModuleVersionRequesterButton