import { ModuleNodeField } from 'Module'
import { useContext } from 'react'
import {
    TypeContextInterfaceNodes,
    TypeEntityInterfaceField,
    TypeNav,
    TypeNavHttpControl,
    TypeReactFlowNodeProps
} from 'Type'
import { FORMAT_NODE_INTERFACE } from 'Format'
import { HookNavGet } from 'Hook'
import { ContextInterfaceNodes } from 'Context'


const ModuleInterfaceFieldNode = ({ data }:TypeReactFlowNodeProps<TypeEntityInterfaceField>) => {
    const context :TypeContextInterfaceNodes = useContext(ContextInterfaceNodes)
    const isComplete :boolean = context.iface.isComplete
    const navControl :TypeNavHttpControl<TypeEntityInterfaceField> = HookNavGet<TypeEntityInterfaceField>({linkTo: (!isComplete) ? data : undefined})
    const nav :TypeNav<any> | undefined = (!isComplete) ? navControl.res?.data : undefined
    return <ModuleNodeField name={data.name} type={data.type} isRequired={data.isRequired} color={FORMAT_NODE_INTERFACE.color.iface} nav={nav} refreshAll={context.refresh}/>
}

export default ModuleInterfaceFieldNode