import { HookNavAction } from 'Hook'
import { useEffect, useState } from 'react'
import { TypeHttpControlAction, TypeHttpPayload, TypeNav, TypeNavControlAction, TypeNavControlActionType,
    TypeNavHttpControlActionAuto } from 'Type'

const HookNavActionAuto = <T>(actionType :TypeNavControlActionType, nav? :TypeNav<T>) :TypeNavHttpControlActionAuto<T> =>  {

    const [submitted, setSubmitted] = useState<number | undefined>()
    const setSubmittedNow = () => setSubmitted(Date.now())

    const [submittedSuccess, setSubmittedSuccess] = useState<number | undefined>()
    const setSubmittedSuccessNow = () => setSubmittedSuccess(Date.now())

    const control :TypeHttpControlAction<TypeNav<T>> = HookNavAction({actionType, nav})
    const action :TypeNavControlAction | undefined = nav?.control.action[actionType]
    const isActionable :boolean = action?.auth.authorized ?? false


    const addToPayload = (payload :TypeHttpPayload) => {
        control.addToPayload(payload)
        setSubmittedNow()
    }

    const submit = async () => {
        await control.submit()
        control.resetPayload()
        setSubmittedSuccessNow()
    }

    useEffect(() => {
        if (submitted && control.isActionable) submit()
    }, [submitted])

    useEffect(() => {
        control.resetPayload()
    }, [nav])

    return { ...control, addToPayload, submittedSuccess, isActionable }
}

export default HookNavActionAuto