import {
    ComponentDivFull,
    ComponentDivMax,
    ComponentEuiFlexGrid,
    ComponentEuiFlexGroup,
    ComponentEuiFlexItem,
    ComponentEuiText
} from 'Component'
import {CSSProperties, ReactNode} from 'react'
import { RecordTableDataCellCategory } from 'Record'
import {TypeEntityCellType, TypeTableDataCell, TypeTableDataCellCategory} from 'Type'
import { UtilColor } from 'Util'

import './ModuleNodeFieldTypeCategory.css'

const style :CSSProperties = {
    fontWeight: 600,
}

type Props = {
    category :TypeTableDataCellCategory,
    type :TypeEntityCellType
    color :string
    paddingTop?: number
    rightActions? :ReactNode
    onClick? :(cellType :TypeEntityCellType) => void
}

const ModuleNodeFieldTypeCategory = ({ category, type, color, paddingTop = 0, rightActions, onClick } :Props) => {

    const innerStyle :CSSProperties = { ...style, paddingTop: `${paddingTop}px`, color: UtilColor.shade(color, -30) }

    return <ComponentEuiFlexGrid columns={1} gutterSize={'s'}>
        <ComponentEuiFlexItem>
            <ComponentEuiFlexGroup>
                <ComponentEuiFlexItem grow>
                    <ComponentEuiText style={innerStyle} className={'eui-textTruncate'}>{ category }</ComponentEuiText>
                </ComponentEuiFlexItem>
                { (rightActions) ? <ComponentEuiFlexItem>{ rightActions }</ComponentEuiFlexItem> : null }
            </ComponentEuiFlexGroup>
        </ComponentEuiFlexItem>
        {Object.entries(RecordTableDataCellCategory).map(([key, value] : [string, TypeTableDataCell]) => (value.category === category) ? <ComponentEuiFlexItem key={key}>
            <ComponentEuiText onClick={(onClick) ?  () => {onClick(key as TypeEntityCellType)} : undefined} style={{color: UtilColor.shade(color, -30) }} className={`eui-textTruncate ModuleNodeFieldTypeCategory${(type === key) ? 'Selected' : 'Unselected'}`}>{ value.title }</ComponentEuiText>
        </ComponentEuiFlexItem> : null)}
    </ComponentEuiFlexGrid>
}

export default ModuleNodeFieldTypeCategory