
import {TypeEntityCellType, TypeTableDataCell} from 'Type'

const RecordTableDataCellCategory :Record<TypeEntityCellType, TypeTableDataCell> = {
    text: {
        title: 'Text',
        category: 'Character'
    },
    big_text: {
        title: 'Big Text',
        category: 'Character'
    },
    currency: {
        title: 'Currency',
        category: 'Numeric'
    },
    decimal: {
        title: 'Decimal',
        category: 'Numeric'
    },
    integer: {
        title: 'Integer',
        category: 'Numeric'
    },
    percentage: {
        title: 'Percentage',
        category: 'Numeric'
    },
    date: {
        title: 'Date',
        category: 'Date/Time'
    },
    datetime: {
        title: 'Date Time',
        category: 'Date/Time'
    },
    datetimez: {
        title: 'Date Time Z',
        category: 'Date/Time'
    },
    time: {
        title: 'Time',
        category: 'Date/Time'
    },
    year: {
        title: 'Year',
        category: 'Date/Time'
    },
    yearmonth: {
        title: 'Year Month',
        category: 'Date/Time'
    },
    file: {
        title: 'Any File',
        category: 'File'
    },
    excel: {
        title: 'Excel (.xlsx, .xls)',
        category: 'File'
    },
    image: {
        title: 'Image',
        category: 'File'
    },
    pdf: {
        title: 'PDF',
        category: 'File'
    },
    word: {
        title: 'Word (.docx)',
        category: 'File'
    },
    bool: {
        title: 'Boolean',
        category: 'Boolean'
    },
    coordinate: {
        title: 'Coordinate',
        category: 'Geometric'
    },
    globalid: {
        title: 'Global ID',
        category: 'Identity'
    },
    userid: {
        title: 'User ID',
        category: 'Identity'
    },
    email: {
        title: 'Email',
        category: 'Contact'
    },
    telephone_number: {
        title: 'Telephone No.',
        category: 'Contact'
    },
    url: {
        title: 'URL',
        category: 'Web'
    },
    reference: {
        title: 'Table Reference',
        category: 'Table'
    },
}

export default RecordTableDataCellCategory
