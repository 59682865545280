import React, { CSSProperties } from 'react'

import { UtilColor } from 'Util'
import { ComponentEuiFlexGroup, ComponentEuiFlexItem } from 'Component'
import { FORMAT_NODE_INTERFACE } from 'Format'
import {
    TypeEntityCellType,
    TypeNav, TypeNavHttpControlAction,
} from 'Type'
import { ModuleNodeFieldNameNew, ModuleNodeFieldRequired, ModuleNodeFieldType} from 'Module'
import { HookNavAction } from 'Hook'

const style :CSSProperties = {
    borderRadius: '4px',
    padding: '8px',
    height: FORMAT_NODE_INTERFACE.height,
    width: FORMAT_NODE_INTERFACE.width,
    backgroundColor: 'white',
    lineHeight: 1,
    visibility: 'visible'
}

type Props = {
    color :string
    nav? :TypeNav<any>
    refreshAll :() => void
}

const buildStyle = (color :string) :CSSProperties => {
    return {
        ...style,
        border: `2px dashed ${UtilColor.shade(color, 0)}`,
        color: UtilColor.shade(color, -30),
    }
}

const defaultType :TypeEntityCellType = 'text'

const ModuleNodeFieldNew = ({ color, nav, refreshAll }: Props) => {

    const actionControl :TypeNavHttpControlAction<any> = HookNavAction<any>({actionType: 'post', nav, initialPayload: {type: defaultType}})
    const { payload } = actionControl

    return <div style={buildStyle(color)}>
        <ModuleNodeFieldNameNew actionControl={actionControl} refreshAll={refreshAll}/>
        <ComponentEuiFlexGroup>
            <ComponentEuiFlexItem grow={1}>
                <ModuleNodeFieldType type={payload.type as TypeEntityCellType} actionControl={actionControl} color={color}/>
            </ComponentEuiFlexItem>
            <ComponentEuiFlexItem>
                <ModuleNodeFieldRequired isRequired={payload.isRequired === 'true'} color={color} actionControl={actionControl}/>
            </ComponentEuiFlexItem>
        </ComponentEuiFlexGroup>
    </div>
}

export default ModuleNodeFieldNew