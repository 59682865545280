import { FORMAT_NODE_INTERFACE } from 'Format'
import { TypeReactFlowNodeXYPosition } from 'Type'

const calcWrapperHeight = (i :number, extra :boolean) :number => {
    return FORMAT_NODE_INTERFACE.padding + FORMAT_NODE_INTERFACE.header +  ((FORMAT_NODE_INTERFACE.height + FORMAT_NODE_INTERFACE.padding) * ((extra) ? i + 1 : i))
}

const calcWrapperHeightCollapsed = (isComplete :boolean) :number => {
    return (FORMAT_NODE_INTERFACE.padding * 2) + FORMAT_NODE_INTERFACE.header + ((isComplete) ? 0 : FORMAT_NODE_INTERFACE.height)
}

const calcNodeStart = (wrapper :TypeReactFlowNodeXYPosition, i :number) :TypeReactFlowNodeXYPosition => {
    return {x: wrapper.x + FORMAT_NODE_INTERFACE.padding, y: FORMAT_NODE_INTERFACE.padding + FORMAT_NODE_INTERFACE.header + (i * (FORMAT_NODE_INTERFACE.height + FORMAT_NODE_INTERFACE.padding))}
}

const UtilNode = {
    calcWrapperHeight,
    calcNodeStart,
    calcWrapperHeightCollapsed
}

export default UtilNode