import { HookNavGet } from 'Hook'
import {TypeEntitySearchResult, TypeNav, TypeNavHttpControl, TypeUrlParam} from 'Type'

const NULL_FIELD :TypeEntitySearchResult = {label: '', key: '', disabled: false}
const ERROR_FIELD :TypeEntitySearchResult = {label: '', key: '', disabled: true}

const HookNavFormFieldSearch = (nav :TypeNav<any>, field :string, value :string) :[TypeEntitySearchResult[], boolean] => {
    const path :string = nav.path

    const paramUpdates :TypeUrlParam[] = [{
        type: 'formSearch-*',
        dynamic: [field],
        values: [value]
    },
    // this is temporary, we need the navigator to return the current search label and value. Otherwise, on patch the current value doesn't always display.
    {
        type: 'size',
        values: ['100']
    }
    ]

    const control :TypeNavHttpControl<TypeEntitySearchResult> = HookNavGet<TypeEntitySearchResult>({linkTo: path, paramUpdates})

    const error :TypeEntitySearchResult[] | undefined = (control.res?.error) ? [ERROR_FIELD] : undefined

    const options :TypeEntitySearchResult[] = [NULL_FIELD, ...(control.res?.data?.data.page?.content ?? [])]

    return [error || options, control.isLoading]
}

export default HookNavFormFieldSearch