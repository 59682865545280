

const awaitAll = async (promises :Promise<any>[]) :Promise<void> => {
    for (let i = 0; i < promises.length; i++) await promises[i]
}

const UtilAsync = {
    awaitAll
}

export default UtilAsync