import {ComponentDivCenter, ComponentEuiFlexGrid, ComponentEuiFlexItem, ComponentEuiSpacer, ComponentNavActionPanel } from 'Component'
import { GlobalSession } from 'Global'
import { HookNavPage } from 'Hook'
import { ModulePage, ModuleUserPasswordResetRequest } from 'Module'
import { useNavigate } from 'react-router-dom'
import {TypeHttpLoadable, TypeHttpPayload, TypeNav, TypeNavHttpControl} from 'Type'
import { UtilGlobalId } from 'Util'

const PageLogin = () => {

    const navigate = useNavigate()

    const setToken = GlobalSession(store => store.setToken)

    const pageControl :TypeNavHttpControl<string> = HookNavPage<string>()
    const pageNav :TypeNav<string> | undefined = pageControl.res?.data

    const toLoad :TypeHttpLoadable[] = [
        {label: 'Login', control: pageControl}
    ]

    const onSuccess = async (payload :TypeHttpPayload, token? :string) => {
        const globalId :string | undefined = setToken(token)
        if (globalId) navigate(UtilGlobalId.toLink(globalId))
    }

    return <ModulePage toLoad={toLoad} control={pageControl}>
        <ComponentDivCenter>
            <ComponentEuiFlexGrid gutterSize={'none'} columns={1}>
                <ComponentEuiFlexItem>
                    { pageNav && <ComponentNavActionPanel title={'Login'} isTitleVerb nav={pageNav} actionType={'post'} onSuccess={onSuccess}/> }
                </ComponentEuiFlexItem>
                <ComponentEuiFlexItem>
                    <ComponentEuiSpacer/>
                    <ModuleUserPasswordResetRequest/>
                </ComponentEuiFlexItem>
            </ComponentEuiFlexGrid>
        </ComponentDivCenter>
    </ModulePage>
}

export default PageLogin