import {PropsWithChildren} from 'react'

import './ComponentPageTitle.css'

type Props = {
    secondary? :boolean
}




const ComponentPageTitle = ({secondary, children} :PropsWithChildren<Props>) =>
    <h1 className={(secondary) ? 'component-page-title-secondary' : 'component-page-title'}>{children}</h1>


export default ComponentPageTitle