
import React, {CSSProperties} from "react";
import {TypeControlSwitch, TypeEntityCellType, TypeNavControlActionFormField, TypeNavHttpControlAction} from 'Type'
import {
    ComponentDivMargin, ComponentEuiButtonIcon,
    ComponentEuiFlexGrid,
    ComponentEuiFlexGroup,
    ComponentEuiFlexItem,
    ComponentEuiPopover,
    ComponentEuiText,
} from 'Component'
import { HookControlSwitch } from 'Hook'
import { ModuleNodeFieldTypeCategory } from 'Module'
import { RecordTableDataCellCategory } from 'Record'

type Props = {
    type :TypeEntityCellType
    color :string
    actionControl :TypeNavHttpControlAction<any>
}

const ModuleNodeFieldType = ({ type, color, actionControl } :Props) => {

    const openControl :TypeControlSwitch = HookControlSwitch()

    const field :TypeNavControlActionFormField | undefined = actionControl.action?.form?.fields.find(f => f.name === 'type')

    const isUpdatable :boolean = Boolean(actionControl.authorized && field)

    const onClick = (isUpdatable) ? async (cellType :TypeEntityCellType) => {
        await actionControl.addToPayload({type: `${cellType}`})
        openControl.turnOff()
    } : undefined

    const className :string | undefined = (isUpdatable) ? 'field-button' : undefined

    const button = <ComponentEuiText size={'s'} className={className} onClick={openControl.toggle}>{ RecordTableDataCellCategory[type].title }</ComponentEuiText>

    return <ComponentEuiPopover button={button} isOpen={openControl.value} closePopover={openControl.turnOff} panelPaddingSize={'m'}>
        <ComponentEuiFlexGrid columns={4}>
            <ComponentEuiFlexItem grow>
                <ModuleNodeFieldTypeCategory type={type} onClick={onClick} category={'Character'} color={color}/>
                <ModuleNodeFieldTypeCategory type={type} onClick={onClick} category={'File'} color={color} paddingTop={16}/>
            </ComponentEuiFlexItem>
            <ComponentEuiFlexItem grow>
                <ModuleNodeFieldTypeCategory type={type} onClick={onClick} category={'Numeric'} color={color}/>
                <ModuleNodeFieldTypeCategory type={type} onClick={onClick} category={'Boolean'} color={color} paddingTop={16}/>
                <ModuleNodeFieldTypeCategory type={type} onClick={onClick} category={'Geometric'} color={color} paddingTop={16}/>
            </ComponentEuiFlexItem>
            <ComponentEuiFlexItem grow>
                <ModuleNodeFieldTypeCategory type={type} onClick={onClick} category={'Date/Time'} color={color}/>
                <ModuleNodeFieldTypeCategory type={type} onClick={onClick} category={'Web'} color={color} paddingTop={16}/>
            </ComponentEuiFlexItem>
            <ComponentEuiFlexItem grow>
                <ModuleNodeFieldTypeCategory type={type} onClick={onClick} category={'Contact'} color={color} rightActions={<ComponentEuiButtonIcon iconType={'cross'} color={'danger'} size={'xs'} onClick={openControl.turnOff}/>}/>
                <ModuleNodeFieldTypeCategory type={type} onClick={onClick} category={'Identity'} color={color} paddingTop={16}/>
                <ModuleNodeFieldTypeCategory type={type} onClick={onClick} category={'Table'} color={color} paddingTop={16}/>
            </ComponentEuiFlexItem>

        </ComponentEuiFlexGrid>

    </ComponentEuiPopover>
}

export default ModuleNodeFieldType