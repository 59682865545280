import React, {ChangeEvent, CSSProperties} from 'react'
import {
    ComponentDivMargin, ComponentEuiButtonIcon,
    ComponentEuiFlexGroup,
    ComponentEuiFlexItem, ComponentEuiIcon,
} from 'Component'
import {FORMAT_NODE_INTERFACE} from 'Format'
import {UtilColor} from 'Util'
import {TypeNavHttpControlAction} from "../../../../../Type";


const inputStyle :CSSProperties = {
    fontWeight: 600,
    color: UtilColor.shade(FORMAT_NODE_INTERFACE.color.iface, -30),
    backgroundColor: UtilColor.shade(FORMAT_NODE_INTERFACE.color.iface, 135),
    border: 'none',
    width: '100%',
    borderRadius: '3px',
    lineHeight: 1,
    outline: 'none',
    padding: '3px'
}

type Props = {
    actionControl :TypeNavHttpControlAction<any>
    refreshAll :() => void
}

const ModuleNodeFieldNameNew = ({ actionControl, refreshAll } :Props) => {

    const onChange = (evt :ChangeEvent<HTMLInputElement>) => actionControl.addToPayload({name: evt.target.value})

    const name :string = actionControl.payload.name as string || ''

    const hasName :boolean = Boolean(name.length > 0)

    const onSubmit = async () => {
        await actionControl.submit()
        actionControl.resetPayload()
        refreshAll()
    }

    return <>
        <ComponentEuiFlexGroup gutterSize={'xs'}>
            <ComponentEuiFlexItem grow>
                <input style={inputStyle} onChange={onChange} value={name}></input>
            </ComponentEuiFlexItem>
            <ComponentEuiFlexItem>
                <ComponentDivMargin margin={0}>
                    <ComponentEuiButtonIcon iconType={'plus'} size={'xs'} color={(hasName) ? 'primary' : 'ghost'} isDisabled={!hasName} onClick={onSubmit}/>
                </ComponentDivMargin>
            </ComponentEuiFlexItem>
        </ComponentEuiFlexGroup>
    </>
}

export default ModuleNodeFieldNameNew