import { ComponentReactFlow } from 'Component'
import {
    TypeContextInterfaceNodes,
    TypeEntityInterface,
    TypeEntityInterfaceField, TypeNavHttpControl,
    TypeReactFlowNode,
    TypeReactFlowNodeTypes, TypeReactFlowNodeXYPosition,
} from 'Type'
import {ModuleInterfaceFieldNode, ModuleInterfaceFieldNodeNew, ModuleInterfaceNode} from 'Module'
import { ContextInterfaceNodes } from 'Context'
import { UtilNode } from 'Util'

type Props = {
    iface :TypeEntityInterface
    fields :TypeEntityInterfaceField[]
    fieldControl :TypeNavHttpControl<TypeEntityInterfaceField>
}

const start :TypeReactFlowNodeXYPosition = {x: 0, y: 0}

const PageInterfaceBody = ({ iface, fields, fieldControl } :Props) => {

    const nodeTypes :TypeReactFlowNodeTypes = {
        iface: ModuleInterfaceNode,
        ifaceField: ModuleInterfaceFieldNode,
        ifaceFieldNew: ModuleInterfaceFieldNodeNew
    }

    const nodes :TypeReactFlowNode[] = [
        {
            id: iface.globalId,
            type: 'iface',
            position: start,
            data: iface
        },
        ...fields.map((field :TypeEntityInterfaceField, index :number) :TypeReactFlowNode => {
            return {
                id: field.linkTo,
                type: 'ifaceField',
                position: UtilNode.calcNodeStart(start, index),
                data: field
            }
        }),
    ]

    if (!iface?.isComplete) nodes.push({
        id: `${iface.globalId}-new`,
        type: 'ifaceFieldNew',
        position: UtilNode.calcNodeStart(start, fields.length),
        data: fieldControl
    })

    const context :TypeContextInterfaceNodes = {iface, fields, refresh: fieldControl.submit}

    return <ContextInterfaceNodes.Provider value={context}>
        <ComponentReactFlow nodes={nodes} nodeTypes={nodeTypes}/>
    </ContextInterfaceNodes.Provider>
}

export default PageInterfaceBody