
import { HookNavPage, HookNavResourceNavAll} from 'Hook'
import { ModulePage } from 'Module'
import {
    TypeEntityInterface,
    TypeEntityInterfaceField,
    TypeHttpLoadable,
    TypeNav,
    TypeNavHttpControl,
} from 'Type'
import {PageInterfaceBody, PageInterfaceSidebar} from 'Page'


const PageInterface = () => {

    const pageControl :TypeNavHttpControl<TypeEntityInterface> = HookNavPage<TypeEntityInterface>()
    const pageNav :TypeNav<TypeEntityInterface> | undefined = pageControl.res?.data
    const iface :TypeEntityInterface | undefined = pageNav?.data.entity

    const fieldControl :TypeNavHttpControl<TypeEntityInterfaceField> = HookNavResourceNavAll<TypeEntityInterfaceField>({key: 'field', nav: pageNav, sortDirection: 'asc'})

    const fields :TypeEntityInterfaceField[] | undefined = fieldControl.res?.data?.data.page?.content

    const onComplete = () => {
        pageControl.submit()
    }

    const toLoad :TypeHttpLoadable[] = [
        { label: 'Interface', control: pageControl },
        { label: 'Fields', control: fieldControl }
    ]

    const sidebar = pageNav && <PageInterfaceSidebar interfaceNav={pageNav} onComplete={onComplete}/>

    return <ModulePage toLoad={toLoad} control={pageControl} sidebarContent={sidebar} sidebarPaddingSize={'m'}>
        { iface && fields && <PageInterfaceBody iface={iface} fields={fields} fieldControl={fieldControl}/> }
    </ModulePage>
}

export default PageInterface