import { ComponentEuiText } from 'Component'
import { UtilColor } from 'Util'
import {TypeNavControlActionFormField, TypeNavHttpControlAction} from 'Type'

type Props = {
    isRequired? :boolean
    color :string
    actionControl :TypeNavHttpControlAction<any>
}

const ModuleNodeFieldRequired = ({ isRequired, color, actionControl } :Props) => {

    const field :TypeNavControlActionFormField | undefined = actionControl.action?.form?.fields.find(f => f.name === 'isRequired')

    const isUpdatable :boolean = Boolean(actionControl.authorized && field)

    const onClick = (isUpdatable) ? () => {
        actionControl.addToPayload({isRequired: `${!isRequired}`})
    } : undefined

    const className :string | undefined = (isUpdatable) ? 'field-button' : undefined

    const colorInner :string | undefined = (isRequired) ? undefined : UtilColor.shade(color, 110)

    return <ComponentEuiText size={'s'} color={colorInner} onClick={onClick} className={className}>{'Required'}</ComponentEuiText>
}

export default ModuleNodeFieldRequired