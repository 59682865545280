import React, { CSSProperties } from 'react'
import { TypeNavHttpControlAction } from 'Type'
import {
    ComponentDivMargin,
    ComponentEuiButtonIcon,
    ComponentEuiFlexGroup,
    ComponentEuiFlexItem,
} from 'Component'

const style :CSSProperties = {
    fontWeight: 600,
    paddingBottom: '10px',
}

type Props = {
    name: string
    deleteAction :TypeNavHttpControlAction<any>
    refreshAll :() => void
}

const ModuleNodeFieldName = ({ name, deleteAction, refreshAll } :Props) => {

    const onClickDelete = async () => {
        await deleteAction.submit()
        refreshAll()
    }

    return <ComponentEuiFlexGroup gutterSize={'xs'}>
        <ComponentEuiFlexItem grow>
            <div className={'eui-textTruncate'} style={style}>{name}</div>
        </ComponentEuiFlexItem>
        { (deleteAction.isActionable) ? <ComponentEuiFlexItem>
            <ComponentDivMargin margin={-2}>
                <ComponentEuiButtonIcon iconType={'trash'} color={'danger'} size={'xs'} onClick={onClickDelete}/>
            </ComponentDivMargin>
        </ComponentEuiFlexItem> : null }
    </ComponentEuiFlexGroup>
}

export default ModuleNodeFieldName