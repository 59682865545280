import {
    ComponentEuiCallout,
    ComponentEuiFlexGroup,
    ComponentEuiFlexItem, ComponentEuiSpacer, ComponentEuiText, ComponentNavSidebarButtonAction,
    ComponentNavSidebarTitle,
} from 'Component'
import { TypeEntityInterface, TypeNav } from 'Type'

type Props = {
    interfaceNav :TypeNav<TypeEntityInterface>
    onComplete :() => void
}

const PageInterfaceSidebar = ({ interfaceNav, onComplete } :Props) => {

    const interfaceEntity :TypeEntityInterface | undefined = interfaceNav.data.entity

    if (!interfaceEntity) return null

    return <ComponentEuiFlexGroup gutterSize={'none'} direction={'column'} style={{height: '100%'}}>
        <ComponentEuiFlexItem grow>
            <ComponentNavSidebarTitle title={interfaceEntity.name} iconType={'indexMapping'} entityName={'Interface'}/>
            <ComponentEuiSpacer size={'m'}/>
            {(interfaceEntity.isComplete) ?
                <ComponentEuiCallout color={'warning'} title={'Complete'} iconType={'check'}>
                    <ComponentEuiText size={'s'}>
                        <p>This interface is complete.</p>
                    </ComponentEuiText>
                </ComponentEuiCallout>
                :
                <ComponentEuiCallout color={'warning'} title={'Under Construction'} iconType={'alert'}>
                  <ComponentEuiText size={'s'}>
                    <p>When you have finished designing this interface, mark it as complete.</p>
                    <p>Once complete, no further changes can be made, and the interface will be ready to use.</p>
                  </ComponentEuiText>
                </ComponentEuiCallout>}
            <ComponentEuiSpacer size={'m'}/>
            { !interfaceEntity.isComplete && <ComponentNavSidebarButtonAction actionType={'patch'} iconType={'check'} nav={interfaceNav} title={'Mark as complete'} color={'success'} onSuccess={onComplete}/> }
        </ComponentEuiFlexItem>
    </ComponentEuiFlexGroup>
}

export default PageInterfaceSidebar