
import React, { useContext } from 'react'
import {
    TypeContextInterfaceNodes,
    TypeEntityInterfaceField,
    TypeNav,
    TypeNavHttpControl,
    TypeReactFlowNodeProps
} from 'Type'
import { FORMAT_NODE_INTERFACE } from 'Format'

import { ModuleNodeFieldNew } from 'Module'
import {ContextInterfaceNodes} from 'Context'

const ModuleInterfaceFieldNodeNew = ({ data }:TypeReactFlowNodeProps<TypeNavHttpControl<TypeEntityInterfaceField>>) => {
    const context :TypeContextInterfaceNodes = useContext(ContextInterfaceNodes)
    const fieldNav :TypeNav<TypeEntityInterfaceField> | undefined = data.res?.data
    return <ModuleNodeFieldNew color={FORMAT_NODE_INTERFACE.color.iface} refreshAll={context.refresh} nav={fieldNav}/>
}

export default ModuleInterfaceFieldNodeNew